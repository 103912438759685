<template>
    <div>
      <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createGroup)" autocomplete="off">

        <div class="row">
          <div class="col-md-2">
          </div>
          <div class="col-md-8">
            <ValidationProvider
              name="campaigngroup"
              rules="min:2"
              v-slot="{ passed, failed }"
            >
              <fg-input type="text"
                      autocomplete="off"
                      :error="failed ? 'Group Name must be at least 2 characters': null"
                      :hasSuccess="passed"
                      name="campaigngroup"
                      label="Name"
                      placeholder="Group Name"
                      v-model="createValue.common_name">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="col-md-2">
          </div>
        </div>


        <div class="text-center">
          <button type="submit" class="btn btn-info btn-fill btn-wd">
            Create
          </button>
          <button type="button" class="btn btn-default btn-fill btn-wd" @click="toggleCreateModal">
            Cancel
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
      </ValidationObserver>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { extend } from "vee-validate";
  import { required, email, min, confirmed } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);
  extend("min", min);
  extend("confirmed", confirmed);

  export default {
    props: ['toggleCreate','createValue','resetFilters'],
    data () {
      return {
        store,
      }
    },
    methods: {
      toggleCreateModal() {
        //this.createValue.id = 0
        //this.createValue.common_name = ''
        //this.createValue.campaign_group_id = 0
        //this.createValue.campaign_id = 0
        //this.createValue.unmapped_campaigns = []

        this.resetFilters()
        this.toggleCreate()
      },
      async createGroup () {
        const userDetails = this.createValue;

        console.log('Your data: ' + JSON.stringify(userDetails))

        // Create map entries & reset page
        await store.createNewMundialCampaignGroup(userDetails);
        this.toggleCreateModal()
      },
    },
    mounted () {
      if (store.mmData.sessionFilters) store.mmData.sessionFilters = false
    },
  }

</script>
<style>

</style>
