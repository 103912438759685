<template>
  <div>
    <!-- Campaign Group table -->
    <campaign-group-tables
      v-if="store.mmData"
      :resetFilters="resetFilters"
      :toggleCreate="toggleCreate"
      :createValue="createValue"
      :setCreateValue="setCreateValue"
    >
    </campaign-group-tables>

    <!-- create Campaign Group modal -->
    <modal :show.sync="modals.createModal"
           footerClasses="justify-content-center"
           type="notice">
      <h3 slot="header" class="modal-title">Create Campaign Group</h3>
      <create-campaign-group-forms
        class="mm-modal mm-create"
        :toggleCreate="toggleCreate"
        :createValue="createValue"
        :setCreateValue="setCreateValue"
        :resetFilters="resetFilters"
      >
      </create-campaign-group-forms>
    </modal>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { Button, Modal } from 'src/components/UIComponents';
  import CreateCampaignGroupForms from 'src/components/Dashboard/Views/Forms/CreateCampaignGroupForms.vue'
  import CampaignGroupTables from 'src/components/Dashboard/Views/Tables/CampaignGroupTables.vue'
  import { extend } from "vee-validate";
  import { required, email, min, confirmed } from "vee-validate/dist/rules";
  
  extend("required", required);
  extend("email", email);
  extend("min", min);
  extend("confirmed", confirmed);

  //
  // Set Dashboard Defaults
  //
  store.campaignSelected = ''

  export default {
    components: {
      CampaignGroupTables,
      CreateCampaignGroupForms,
      Modal,
      [Button.name]: Button,
    },
    data () {
      return {
        store,
        modals: {
          createModal: false,
          editModal: false,
        },
        createValue: {
          id: 0,
          common_name: '',
          campaign_group_id: 0,
          campaign_id: 0,
          cdate: (new Date()).toISOString().split('T')[0],
          udate: (new Date()).toISOString().split('T')[0],
          unmapped_campaigns: [],
        },
      }
    },
    methods: {
      resetFilters () {
        // Update local
        this.createValue.id = 0
        this.createValue.common_name = ''
        this.createValue.campaign_group_id = 0
        this.createValue.campaign_id = 0
        this.createValue.unmapped_campaigns = []
      },
      setCreateValue(obj) {
        console.log(obj)

        const keyArr = Object.keys(obj),
        keyLen = keyArr.length;

        for (let k = 0; k < keyLen; k++) {
          const tmpKey = keyArr[k];

          this.createValue[tmpKey] = obj[tmpKey]
        }

        console.log({createValue:this.createValue})
      },
      toggleCreate() {
        this.modals.createModal = !this.modals.createModal
      },
    },
    async mounted () {
      //console.log({store})
      if (store.mmData.sessionFilters) store.mmData.sessionFilters = false
    }
  }
</script>
<style>
  .mm-create {
    overflow-y: visible !important;
  }
</style>
