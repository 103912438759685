<template>
  <div v-if="!store.mmData.user.display_tc" class="row">
    <div class="col-md-12">
      <h4 class="title">Unmapped Campaign List</h4>
    </div>
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">
          utilities table
          <button id="exportMM" class="btn btn-badge btn-round btn-warning pull-right" @click.prevent="createMap" >
            create groups <i class="nc-icon nc-simple-add"></i>
          </button>          
        </div>
      </div>
      <div class="card-body row body-mm">
        <div class="col-sm-2">
          <div class="form-group">
            <fg-input label="Rows" v-if="store.mmData">
              <el-select
                class="select-default mm-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
        <div class="col-sm-8">
          <!-- empty -->
        </div>
        <div class="col-sm-2">
          <div class="pull-right">
            <fg-input class="input-sm search-mm"
                      placeholder="Search"
                      v-model="searchQuery"
                      addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped"
                    :data="queriedData"
                    border
                    style="width: 100%">
            <el-table-column v-for="column in tableColumns"
                             :sortable="false"
                             :align="column.align"
                             :key="column.label"
                             :min-width="store.colWidthDict[column.prop]"
                             :prop="column.prop"
                             :label="column.label">

              <!-- Map Bool -->
              <template v-if="column.prop === 'map_bool'" #default="{ row }">
                <p-checkbox v-model="row.map_bool" @click.native="handleCheckbox(row, column.prop)"></p-checkbox>
              </template>

            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import { store } from 'src/store'
  import { unmappedCampaignCols, unmappedCampaignSearch } from './MundialColumns'
  import users from './users'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)

  export default{
    props: ['toggleCreate','setCreateValue','resetFilters'],
    components: {
      PPagination
    },
    methods: {
      handleCheckbox(row, prop) {
        console.log({row, prop})

        const tmpVal = row[prop],
        newVal = typeof tmpVal === 'boolean' ? !tmpVal : false,
        parentRow = store.mmData.unmapped_campaign_list.find(campaign => campaign.id === row.id);

        console.log({newVal, parentRow})

        if (newVal) {
          this.mapSet.add(row.id)
        } else {
          this.mapSet.delete(row.id)
        }

        console.log({mapSet:this.mapSet})
      },
      createMap () {
        console.log({store,queriedData:this.queriedData})

        const mapSet = this.mapSet,
        mapData = {},
        unmappedCampaigns = JSON.parse(JSON.stringify(store.mmData.unmapped_campaign_list.filter(x => x.map_bool || mapSet.has(x.id)))),
        mainRow = unmappedCampaigns[0];

        mapData['id'] = mainRow.id
        mapData['common_name'] = mainRow.common_name
        mapData['campaign_id'] = mapData['id']
        mapData['campaign_group_id'] = mapData['id']
        mapData['unmapped_campaigns'] = unmappedCampaigns

        console.log({mapSet, mapData, unmappedCampaigns})

        this.setCreateValue(mapData)
        this.toggleCreate()
      },
    },
    computed: {
      tableColumns () {
        //console.log({unmappedCampaignCols})
        return unmappedCampaignCols
      },
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        const propsToSearch = unmappedCampaignSearch,
        lowerSearchQuery = this.searchQuery.toLowerCase()
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of propsToSearch) {
              let rowValue = row[key].toString().toLowerCase()
              if (rowValue.includes && rowValue.includes(lowerSearchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      },
      generateSummary () {
        const metricArr = ['map_bool','active_flag','ignore_flag'],
        metricSet = new Set(metricArr);
        let mundialData = JSON.parse(JSON.stringify(store.mmData.unmapped_campaign_list ? store.mmData.unmapped_campaign_list : []));

        // Sanitize & Standardize
        mundialData = mundialData.length ? store.sanitizeMetrics(mundialData, metricSet, false) : []
        return mundialData.length ? mundialData : []
      },
      tableData () {
        // Toggle Aggregation
        let cleanRows = [];
        if (store.mmData) {
          cleanRows = this.generateSummary
        }
        //console.log({cleanRows})
        return cleanRows;
      },
      mapBool () {
        console.log({mapBool:[...this.mapSet].length})

        return [...this.mapSet].length
      },
    },
    data () {
      return {
        store,
        MUNDIAL_PLATFORM,
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [25, 50, 100, 200],
          total: 0
        },
        searchQuery: '',
        mapSet: new Set([]),
      }
    },
    mounted () {
      // Generate naming dictionary
      const tableColumns = unmappedCampaignCols;
      for (let c = 0; c < tableColumns.length; c++) {
        const tmpCol = tableColumns[c];
        store.colsDict[tmpCol.prop] = tmpCol.label
      }

      // Log to console
      //console.log({store})
    },
  }
</script>
<style>
  .mmWarning {
    color: #ef8157;
  }
  #exportMM {
    color: black;
  }
  .search-mm {
    margin-top: 25px;
  }
  .body-mm {
    padding-top: 0px !important;
  }
</style>
<style lang="scss">
  .el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
  }
</style>
